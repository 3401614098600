import { useEffect, useState } from 'react'
import type { UseClearRefinementsProps, UseRefinementListProps } from 'react-instantsearch'
import {
  useClearRefinements,
  useCurrentRefinements,
  useRefinementList,
} from 'react-instantsearch'

import { grey } from '@knauf-group/ct-designs/themeConfigs/rebranding/colors'
import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  listItemIconClasses,
  ListItemText,
  styled,
  Typography,
} from '@mui/material'
import { type RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'

import { useFilterSelectionContext } from '@/providers/c/FilterSelectionProvider'
import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'
import { convertToHTMLTagId } from '@/utils/plain/convertToHTMLTagId'

type CustomAccordionSummaryAdditionalProps = {
  expanded: boolean
}

const CustomAccordionSummary = styled(AccordionSummary, {
  name: 'CustomAccordionSummary',
  shouldForwardProp: (prop) => prop !== 'expanded',
})<CustomAccordionSummaryAdditionalProps>(({ theme, expanded }) => ({
  minHeight: '42px',
  borderLeft: `2px solid ${expanded ? theme.palette.secondary.main : grey['200']}`,
  borderBottom: 'none',
  borderTop: 'none',
}))

export type MultiSelectAccordionFilterProps = UseRefinementListProps & {
  title?: string
  hide?: boolean
} & QaHook &
  UseClearRefinementsProps

export const MultiSelectAccordionFilter = (props: MultiSelectAccordionFilterProps) => {
  const { title, dataCy, hide = false, ...useRefinementListProps } = props
  const { attribute } = useRefinementListProps
  const [isExpanded, setIsExpanded] = useState(false)
  const { refine: clearRefinement } = useClearRefinements({
    includedAttributes: [attribute],
  })
  const { t } = useT({ keyPrefix: 'catalog.filters' })
  const { trackResetFiltersEvent } = useCategoriesAnalyticsTracking()

  const [showResetButton, setShowResetButton] = useState(false)
  const { items: refinementList } = useRefinementList(useRefinementListProps)
  const { items: currentRefinements } = useCurrentRefinements()

  const activeRefinements = currentRefinements
    .filter((refinement) => refinement.attribute === attribute)
    .flatMap((refinement) =>
      refinement.refinements.map((item) => ({
        value: String(item.value),
        label: item.label,
        isRefined: true,
        count: 0,
      })),
    )
  const combinedItems = refinementList.map((item) => {
    const activeItem = activeRefinements.find((ref) => ref.value === item.value)
    return {
      ...item,
      isRefined: Boolean(activeItem),
    }
  })
  activeRefinements.forEach((activeItem) => {
    if (!combinedItems.some((item) => item.value === activeItem.value)) {
      combinedItems.push(activeItem)
    }
  })
  const { selectedFilters, addFilter, removeFilter, clearFilters } = useFilterSelectionContext()

  useEffect(() => {
    if (combinedItems.some((item) => (selectedFilters[attribute] || []).includes(item.value))) {
      setShowResetButton(true)
    } else {
      setShowResetButton(false)
    }
  }, [selectedFilters, combinedItems, attribute])

  // we hide the filter from parent conditions (e.g. product type not selected)
  if (hide) return null
  // we hide the filter if there is 0 options available
  if (combinedItems.length === 0) return null

  const selectedItemsCount = selectedFilters[attribute]?.length
  const hasSelections = selectedItemsCount > 0
  const titleWithSelection = `${title}${hasSelections ? ` (${selectedItemsCount})` : ''}`

  const onClickResetFilters = () => {
    clearRefinement()
    trackResetFiltersEvent(t('reset'), [attribute], Object.values(selectedFilters[attribute]))
    clearFilters(attribute)
  }

  const handleCheckboxClick = (category: string, value: string) => {
    if ((selectedFilters[category] || []).includes(value)) {
      removeFilter(category, value)
    } else {
      addFilter(category, value)
    }
  }

  const sortItems = (items: RefinementListItem[]) =>
    items.sort((a, b) => {
      if (a.isRefined !== b.isRefined) {
        // when either `a` or `b` is refined but not both,
        if (a.isRefined) {
          // if `a` is refined, it comes before `b`
          return -1
        }
        // `b` is refined but not `a` => `b` comes before `a`
        return 1
      }

      // either both `a` and `b` are refined or neither => usual sorting logic
      return a.label.localeCompare(b.label)
    })

  const sortedItems: RefinementListItem[] = sortItems(combinedItems)

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded)
      }}
      sx={{
        borderBottomWidth: '0px !important',
        '&.MuiAccordion-root:before': { position: 'unset' },
      }}
      data-cy={`Accordion-${dataCy}`}
    >
      <CustomAccordionSummary
        expanded={isExpanded}
        expandIcon={
          isExpanded ? (
            <RemoveOutlinedIcon fontSize="small" />
          ) : (
            <AddOutlinedIcon fontSize="small" />
          )
        }
      >
        <Typography variant="body3bold" sx={{ lineHeight: '26px' }}>
          {titleWithSelection}
        </Typography>
      </CustomAccordionSummary>
      <AccordionDetails sx={{ padding: '0px' }}>
        <List>
          {sortedItems.map((item) => {
            const htmlTagId = convertToHTMLTagId(`${title}-${item.value}`)

            return (
              <ListItem
                key={item.value}
                disableGutters
                onClick={() => {
                  handleCheckboxClick(attribute, item.value)
                }}
                data-cy={`Accordion-${dataCy}-ListItem-${item.value}`}
                sx={{ padding: '4px 0px' }}
              >
                <ListItemIcon sx={{ [`&.${listItemIconClasses.root}`]: { minWidth: '50px' } }}>
                  <Checkbox
                    checked={selectedFilters[attribute]?.includes(item.value) ?? false}
                    disableRipple
                    color="secondary"
                    inputProps={{ 'aria-labelledby': htmlTagId }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{ variant: 'body3dense', id: htmlTagId }}
                />
              </ListItem>
            )
          })}
        </List>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ width: '100%', padding: '8px 16px' }}
          disabled={!showResetButton}
          onClick={onClickResetFilters}
          data-cy={`reset-facet-${dataCy}`}
        >
          {t('reset')}
        </Button>
      </AccordionDetails>
    </Accordion>
  )
}
